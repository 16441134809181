html {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactCrop__child-wrapper > img,
.ReactCrop__child-wrapper > video {
  max-height: 490px !important;
}
.buttonLink {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  width: 80px;
  display: flex;
  align-items: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--palette-textMain);
  -webkit-box-shadow: 0 0 0px 1000px var(--palette-surface-darken) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.post-gallery .slick-arrow {
  padding: 0;
  background: var(--palette-surface-main);
  border-radius: 50px;
  width: 48px;
  height: 48px;
  transition: background 0.3s;
  z-index: 2;
}
.post-gallery .slick-arrow:hover {
  background: #3d4bb5;
}
.post-gallery .slick-next {
  right: 40px;
}
.post-gallery .slick-prev {
  left: 40px;
}
.post-gallery .slick-next svg {
  transform: rotate(180deg);
}
.post-gallery .slick-arrow:before {
  content: none;
}
