@import "assets/_colors.scss";
@import "assets/_sizes.scss";
@import "react-quill/dist/quill.snow.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "Picker.scss";
@import "react-circular-progressbar/dist/styles.css";

* {
  box-sizing: border-box;
}

#root {
  background: var(--palette-themeBackground4);
  position: relative;
  min-height: 100vh;
}

html,
body {
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  font-family: "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC",
  sans-serif;
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    background: var(--palette-scrollbar-track-background);
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--palette-scrollbar-thumb-background);
    border-radius: 8px;
    border: 2px solid var(--palette-scrollbar-thumb-borderColor);
  }
}

body {
  background-color: var(--palette-themeBackground4);
  &.mobile-menu-open {
    position: fixed;
    overflow: hidden;
  }
}

.App {
  text-align: center;
  color: var(--palette-surface-darken);
  font-size: 16px;
}

.ql-editor {
  padding: 0;

  strong {
    font-family: "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC",
      sans-serif;
    font-weight: bold;
  }
}

.MuiButton-contained
  .MuiTouchRipple-root
  .MuiTouchRipple-ripple.MuiTouchRipple-rippleVisible {
  opacity: 0.2;
}
.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary
  .MuiTouchRipple-root
  .MuiTouchRipple-child {
  background: #7ae9f3;
}
.MuiButton-containedError {
  background: $dark-coral !important;
}

a {
  color: var(--palette-textLink);
  text-decoration: none;
}
.Subtitle-1 {
  font-size: 16px;
}
.App-label {
  font-family: "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC",
    sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: var(--palette-secondary-color);
  margin-bottom: 6px;
}
.SemiBoldTxt {
  font-weight: 600;
}
.MuiFormControl-root {
  width: 100%;
}
.Mui-error {
  color: var(--palette-error-main);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.MainTitle {
  font-weight: 500;
  color: white;
  font-size: 54px;
  line-height: 66px;
  text-transform: uppercase;
  font-family: "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC",
    sans-serif;
}
.display-linebreak {
  white-space: pre-line;
}
.App-flexCenter {
  display: flex;
  align-items: center;
  &-between {
    justify-content: space-between;
  }
}

.MuiAutocomplete-noOptions {
  color: #fff;
}
.fontDisplay {
  font-family: "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC",
    sans-serif;
}
.textLighter {
  color: rgba(255, 255, 255, 0.5);
}
#header-menu {
  .MuiPopover-paper {
    overflow: unset;
    border-radius: 12px;
  }
}
.MuiAlert-outlinedError {
  border-color: var(--palette-error-main);
  align-items: center;
  padding: 6px 16px;
  width: calc(100% - 32px);
  .MuiAlert-icon {
    color: var(--palette-error-main);
  }
  .MuiAlert-message {
    color: var(--palette-error-main);
  }
}
input:focus {
  outline: none;
}
input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.payments-3ds-verification-iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 400px;
  height: 100%;
  max-width: 600px;
  width: 100%;
  background: var(--palette-secondary-color);
  border-radius: 10px;
}

@media (max-width: $tablet-breakpoint-xs) {
  body::-webkit-scrollbar {
    display: none;
  }
}
.MuiBackdrop-root.MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  &.MuiBackdrop-invisible {
    background-color: transparent;
  }
  &.not-transparent {
    background-color: var(--palette-surface-darken);
  }
}
.MuiSkeleton-root.MuiSkeleton-rectangular {
  background: var(--palette-skeleton);
  border-radius: 8px;
}
*:focus-visible {
  outline: none;
}

.overflow-hidden {
  overflow: hidden;
}

.slick-next {
  transform: translate(0, -50%) rotate(180deg) !important;
}

.slick-next,
.slick-prev {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px !important;
  height: 28px !important;
  padding: 0;
  border-radius: 10px;
  background: var(--palette-slider-arrowsBg) !important;
  box-shadow: var(--palette-slider-boxShadow);
  z-index: 1;
  &:before {
    content: "" !important;
    background: url(/assets/svg/arrow-left-dark.svg) no-repeat center center;
    padding: 6px;
    opacity: 1;
    background-size: 24px 24px;
  }
}
.slick-disabled {
  display: none !important;
}

.slick-dots {
  bottom: 16px !important;
  li {
    height: 8px !important;
    width: 8px !important;
    margin: 0 3px !important;
    button {
      height: 100% !important;
      width: 100% !important;
      padding: 0 !important;
      background: var(--palette-slider-dots) !important;
      border-radius: 50%;
      &:before {
        content: none !important;
      }
    }
    &.slick-active {
      width: 16px !important;
      button {
        border-radius: 8px !important;
        background: var(--palette-accent-secondary) !important;
      }
    }
  }
}

.opacity_07 {
  opacity: 0.7;
}

.opacity_05 {
  opacity: 0.5;
}

.page-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 17px 0;
  width: 100%;
  max-width: 1340px;
}

.custom-error-modal-cancel {
  font: 400 18px / 1 "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC";
}

.custom-error-modal-submit {
  font: 500 18px / 1 "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC";
  background: $dark-coral !important;
}

@media all and (max-width: 1440px) {
  .page-container {
    padding: 17px 16px 0;
    max-width: 100%;
  }
}

@media all and (max-width: $tablet-breakpoint-s) {
  .slick-next,
  .slick-prev {
    display: none !important;
  }
}

@media all and (max-width: $mobile-breakpoint) {
  .page-container {
    padding: 0 16px;
  }
}
