.indicatorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  //spin animation
  @keyframes spin {
      0% {
      transform: rotate(0deg);
      }
      100% {
      transform: rotate(360deg);
      }
  }
  .indicator {
    position: relative;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
}
