@import "react-datepicker/dist/react-datepicker.css";

.react-datepicker {
  box-shadow: 0 4px 24px 0 var(--palette-surface-shadow);
  border-radius: 10px !important;
  border: 1px solid var(--palette-surface-border) !important;
  overflow: hidden;
  color: var(--palette-mainTextColor) !important;
  background-color: var(--palette-surface-main) !important;
}

.react-datepicker__month-container {
  background-color: var(--palette-surface-main) !important;
}

.react-datepicker__header {
  background-color: var(--palette-surface-main) !important;
  border-radius: 0 !important;
  border-bottom: none !important;
  padding: 16px 16px 0 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--palette-datePicker-color) !important;
  font: 500 15px / 1 "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC",
  sans-serif !important;
}

.react-datepicker__navigation {
  top: 10px !important;
}

.react-datepicker__navigation--previous {
  left: 10px !important;
}

.react-datepicker__navigation--next {
  right: 10px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0 !important;
  color: var(--palette-datePicker-color) !important;
  font: 400 15px / 1 "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC",
  sans-serif !important;
  height: 48px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.react-datepicker__day:not(.react-datepicker__day--disabled) {
  background: var(--palette-datePicker-background) !important;
}

.react-datepicker__day-name {
  font: 400 14px / 1 "Inter", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC",
  sans-serif !important;
  opacity: 0.5;
}

.react-datepicker__day--outside-month:not(
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range
  ) {
  opacity: 0.5;
}

.react-datepicker__day--disabled {
  opacity: 0.5;
}
//.react-datepicker__day--keyboard-selected[aria-selected="true"],
//.react-datepicker__day--in-selecting-range {
//  background-color: $dark-violet !important;
//}

//.react-datepicker__day:hover {
//  background-color: $border-color2 !important;
//}
//.react-datepicker__day--in-range {
//  background-color: $border-color2 !important;
//  border-radius: 0 !important;
//}

//.react-datepicker__day--range-start {
//  border-radius: 8px 0 0 8px !important;
//  background-color: $dark-violet !important;
//}
//
//.react-datepicker__day--range-end {
//  border-radius: 0 8px 8px 0 !important;
//  background-color: $dark-violet !important;
//}

.react-datepicker__day--range-start.react-datepicker__day--range-end {
  border-radius: 8px !important;
}

.modal-calendar-range-picker {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    background-color: initial;
  }
}

.scheduled-calendar {
  .react-datepicker__day:hover {
    background-color: inherit;
  }
  .react-datepicker__day--selected {
    background: var(--palette-datePicker-backgroundActive) !important;
    &:hover {
      background: var(--palette-datePicker-backgroundActive) !important;
    }
  }
  .react-datepicker__day--keyboard-selected {
    background-color: inherit;
  }
  //.react-datepicker__day--highlighted {
  //  background-color: $dark-purple2 !important;
  //  border-radius: 50%;
  //  &:hover {
  //    background-color: $dark-purple2 !important;
  //    border-radius: 50%;
  //  }
  //}
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 47px;
    height: 47px;
  }
  .react-datepicker__month {
    margin-top: 8px;
  }
}
